import React from 'react';
import styles from '../assets/css/ClientLoader.module.css';

const ClientLoader = () => {
  return (
    <div className={styles.loaderContainer}>
      <img src={'/oldassets/img/weblogo.jpg'} alt="Car Loader" className={styles.carImage} />
      <div className={styles.road}></div>
    </div>
  );
};

export default ClientLoader;
